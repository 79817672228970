@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@400;700&display=swap');

body{
    margin: 0;
    padding: 0;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
    color: #fff;

}

*{
    cursor: default;
}

.intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: url(img/bg_01.png) center center no-repeat;
    background-size: cover;
    overflow: hidden;
}

.langs-choice,
.social-media,
.logo,
.slogan,
.advant{
    display: flex;
    justify-content: space-between;
    margin: auto;

}

.langs-choice{
    color: #4885ed;
    margin: 30px auto;
    margin-top: 5%;

}
.langs-choice div{
    margin: auto 10px;
    text-shadow: 0 5px 20px #000;
    cursor: pointer;
    position: relative;
}
.langs-choice .active{
    color: #fff;
}
.langs-choice div:after{
    content: '';
    position: absolute;
    width: 0px;
    height:30px;
    border-bottom: 1px solid #fff;
    transition: 0.5s;
    left: -2px;
    top: 0;
    visibility: hidden;
    opacity: 0;
}
.langs-choice div:hover:after{
    width: 30px;
    visibility: visible;
    opacity: 1;
}

.social-media{
    margin: 10px auto;

}
.social-media .om,
.social-media a{
    display: block;
    margin: auto 20px;
    text-decoration: none;
    
}
.social-media .sm{
    width: 30px;
    height: 30px;
    background-size: contain;
    position: relative;
    cursor: pointer;
}
.social-media .sm.wa{
    background: url(img/wa.png) center center no-repeat;
}
.social-media .sm.em{
    background: url(img/em.png) center center no-repeat;
    width: 40px;
}
.social-media .sm.ph{
    background: url(img/ph.png) center center no-repeat;
}
.social-media .sm:after{
    content: '';
    position: absolute;
    width: 0px;
    height:0;
    border-top: 1px solid #fff;
    background: #ffffff0d;
    transition: 0.5s;
    left: -2px;
    top: -5px;
    visibility: hidden;
    opacity: 0;
}

.social-media .sm:hover:after{
    width: 35px;
    height: 40px;
    visibility: visible;
    opacity: 1;
}
.social-media .sm.em:hover:after{
    width: 47px;
    height: 40px;
    visibility: visible;
    opacity: 1;
}




.logo{
    background: url(img/logo.png) center center no-repeat;
    width: 100%;
    height: 20%;
    min-height: 150px;
    background-size: contain;
}

h1.slogan{
    margin: 30px auto;
    padding: 0 20px;
    font-size: 24px;
    text-align: center;
    font-weight: normal;
}

.advant{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.advant div{
    width: 220px;
    height: 80px;
    border-radius: 10px;
    background: linear-gradient(to bottom, #ffffff85, #ffffff0d);
    margin: auto 40px;
    position: relative;
    padding: 5px 10px;
    padding-top: 120px;
    text-transform: uppercase;
    font-size: 24px;
    margin: 20px;

}
.advant div:before{
    content: '';
    position: absolute;
    width: 130px;
    height: 130px;
    right: 5px;
    top: -15px;

}
.advant .vys-eff:before{
    background: url(img/vys-eff.png) center top no-repeat;
    background-size: contain;
}
.advant .dol-sot:before{
    background: url(img/dolg-sotr.png) center top no-repeat;
    background-size: contain;
    width: 120px;
}
.advant .kac-pro:before{
    background: url(img/kac-pro.png) center top no-repeat;
    background-size: contain;
    width: 110px;
}

.advant div:before,
/* .logo, */
.counter .block .number {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    }
    @-webkit-keyframes pulse {
        0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
        50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
        }
        100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
    }
    @keyframes pulse {
        0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
        50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
        }
        100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        }
    } 



.counter{
    background: linear-gradient(to bottom, #000, #1d174a, #331735);
    min-height: 50vh;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
}
.counter h2{

    margin: 20px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    font-size: 3em;
}
.counter .row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.counter .block{
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: linear-gradient(to top, #ffffff85, #ffffff0d);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
}
.counter .block .number{
    font-size: 100px;
    font-weight: 700;
    text-shadow: 0 -2px 0 #000;
}
.counter .block .text{
    font-size: 20px;
    /* text-transform: uppercase; */
    text-align: center;
}